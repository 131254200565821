import { Box } from '@mui/material';
import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import { breakpoints } from '@/theme';
export const FullContainer = styled(Box) `
  && {
    height:  ${({ theme }) => `calc(100vh - ${theme.footer.height} - ${theme.appHeader.height})`};
    width: 100%;
    margin: 0;
    padding: 0;

    .actionable-insights {
      padding-top: 1.25rem;
      padding-left: 3.75rem;
      padding-right: 3.75rem;
    }

    @media (max-width: ${breakpoints.md}) {
      height: ${({ theme }) => `calc(100vh - ${theme.footer.heightMobile} - ${theme.appHeader.height})`};
    }
  }
`;
export const AnalyticsWrapper = styled.div `
  width: 100%;
  top: 60px;
  position: relative;
`;
export const LoadingOverlay = styled.div `
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${baseColors.background000};
`;
