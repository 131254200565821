import moment from 'moment';
import { DATE_FORMAT_HOUR } from '@/constants';
import { convertToISO, getDuration } from '@/utils';
const TWENTY_FIVE_DAYS_DST = 601;
export function createDateRangeEnding(day = 14) {
    return {
        start: moment()
            .startOf('day')
            .subtract(day, 'days')
            .utc()
            .format(DATE_FORMAT_HOUR),
        end: moment()
            .startOf('day')
            .utc()
            .format(DATE_FORMAT_HOUR),
    };
}
export function getStartOfMonth() {
    return moment()
        .startOf('month')
        .utc()
        .format(DATE_FORMAT_HOUR);
}
export function getYesterdayDate() {
    return getPreviousDate(1);
}
export function getPreviousDate(daysAgo) {
    return moment()
        .hours(0)
        .subtract(daysAgo, 'day')
        .toDate();
}
export function getTimeFormat(start, end, datetime) {
    const duration = getDuration(start, end);
    return moment(datetime)
        .local()
        .format(duration <= TWENTY_FIVE_DAYS_DST ? 'Do hA' : 'MMM DD');
}
export function getMinutesCurrentHour() {
    return moment().minutes();
}
export function isDst(datetime) {
    return moment(convertToISO(datetime)).isDST();
}
export function getDaysBetweenInclusive(startDate, endDate) {
    // Convert input to Date objects if they're strings
    const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate;
    // Ensure both dates are valid
    if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
        throw new Error('Invalid date provided.');
    }
    // Check if start is after end
    if (start > end) {
        throw new Error('Start date cannot be later than end date.');
    }
    // Normalize both dates to midnight to ignore the time
    const startMidnight = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
    const endMidnight = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
    // Calculate the difference in time
    const differenceInMilliseconds = endMidnight - startMidnight;
    // Convert milliseconds to days and add 1 for inclusivity
    return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
}
