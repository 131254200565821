import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const InsightIcon = (_a) => {
    var { width = 24, height = 25 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.94476 8.52561C7.89873 8.54278 7.85692 8.56986 7.82223 8.60497C7.78754 8.64008 7.76079 8.68239 7.74382 8.72898L6.88497 11.084L6.02781 8.7307C6.01087 8.68362 5.98396 8.64086 5.94895 8.60543C5.91394 8.57 5.8717 8.54276 5.82518 8.52561L3.5 7.65809L5.82518 6.79056C5.8717 6.77342 5.91394 6.74618 5.94895 6.71075C5.98396 6.67532 6.01087 6.63256 6.02781 6.58548L6.88497 4.23218L7.74213 6.58548C7.75909 6.63207 7.78584 6.67438 7.82054 6.70949C7.85523 6.7446 7.89703 6.77168 7.94306 6.78885L10.2699 7.65809L7.94476 8.52561Z" fill="#3B59ED"/>
    <path d="M8.08776 18.3759C8.05878 18.385 8.03246 18.3994 8.01062 18.418C7.98878 18.4367 7.97194 18.4591 7.96126 18.4839L7.42056 19.735L6.88094 18.4848C6.87027 18.4598 6.85333 18.4371 6.83129 18.4183C6.80925 18.3994 6.78266 18.385 6.75337 18.3759L5.28955 17.915L6.75337 17.4541C6.78266 17.445 6.80925 17.4305 6.83129 17.4117C6.85333 17.3929 6.87027 17.3702 6.88094 17.3452L7.42056 16.095L7.96019 17.3452C7.97087 17.3699 7.98771 17.3924 8.00955 17.411C8.03139 17.4297 8.05771 17.4441 8.08669 17.4532L9.55158 17.915L8.08776 18.3759Z" fill="#3B59ED"/>
    <path d="M15.2758 15.7942C15.1724 15.8307 15.0785 15.8882 15.0005 15.9628C14.9226 16.0374 14.8625 16.1274 14.8244 16.2264L12.8947 21.2308L10.9689 16.23C10.9309 16.13 10.8704 16.0391 10.7917 15.9638C10.7131 15.8885 10.6182 15.8306 10.5136 15.7942L5.28955 13.9507L10.5136 12.1072C10.6182 12.0708 10.7131 12.0129 10.7917 11.9376C10.8704 11.8623 10.9309 11.7715 10.9689 11.6714L12.8947 6.67065L14.8206 11.6714C14.8587 11.7704 14.9188 11.8603 14.9967 11.9349C15.0747 12.0096 15.1686 12.0671 15.272 12.1036L20.4999 13.9507L15.2758 15.7942Z" fill="#3B59ED"/>
  </SvgIcon>);
};
export default InsightIcon;
